import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from "./routers/PrivateRoute.js";
import Header from "./components/Header/Header.js";
import Home from "./components/MainPages/HomePage.js";
import LoginPage from "./components/MainPages/LoginPage.js";
import ManageUserPage from "./components/MainPages/ManageUserPage.js";
import Dashboard from "./components/PerformanceDashboardComponents/Dashboard.js";
import TicketContainer from "./components/TicketComponents/TicketContainer.js";
import ScrollToTop from "./components/Design/ScrollToTop.js";
import NotFound from "./components/CommonPages/NotFound.js"
import ErrorPage from "./components/CommonPages/ErrorPage.js";
import UnauthorizedPage from "./components/CommonPages/Unauthorized.js";
import LoadingIndicator from "./components/CommonPages/LoadingIndicator.js";

import OAuth2RedirectHandler from "./oauth2/OAuthRedirectHandler.js";
import { ToastContainer } from 'react-toastify';
import { AuthProvider, useAuth } from './auth/AuthHandler.js';
import { WebSocketProvider } from "./components/WebSocket/WebSocketContext.js";
import { VAPID_KEY, API_BASE_URL } from "./constants/index.js";
import { useApiClient } from "./service/API.js";

const App = () => {
  const location = useLocation();
  const {  expiration, authenticated, userEmail, userName, userRoles, loading, handleLogout, handleLogin} = useAuth();
  const apiClient = useApiClient();

  // Request notification permission and subscribe
  const requestNotificationPermission = async (registration) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        subscribeToPushNotifications(registration);
      } else {
        console.log('Notification permission denied.');
      }
    } catch (error) {
      console.error('Notification permission request failed:', error);
    }
  };

  // Subscribe to push notifications
  const subscribeToPushNotifications = async (registration) => {
    try {
      const existingSubscription = await registration.pushManager.getSubscription();

      if (existingSubscription) {
        console.log('Unsubscribing from previous subscription');
        await existingSubscription.unsubscribe();
      }

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_KEY),
      });
      console.log('New Subscription:', subscription);
      sendSubscriptionToBackend(subscription);
      // Save the user's email and subscription in the cache
      caches.open('push-subscription-cache').then((cache) => {
        // Store both the subscription and the email
        const subscriptionData = {
          email: userEmail,
          subscription: subscription,
          apiURL: API_BASE_URL
        };
        // Store the subscription data in the cache as a JSON response
        return cache.put('user-subscription', new Response(JSON.stringify(subscriptionData)));
      });
    } catch (err) {
      console.error('Push subscription failed:', err);
    }
  };

  // Send subscription data to the backend
  const sendSubscriptionToBackend = async (subscription) => {
    const p256dh = subscription.getKey('p256dh');
    const auth = subscription.getKey('auth');

    if (p256dh && auth) {
      const p256dhBase64 = btoa(String.fromCharCode(...new Uint8Array(p256dh)));
      const authBase64 = btoa(String.fromCharCode(...new Uint8Array(auth)));

      const subscriptionData = {
        email: userEmail,
        endpoint: subscription.endpoint,
        p256dh: p256dhBase64,
        auth: authBase64,
      };

      try {
        const response = await apiClient.post('/service/subscribe', subscriptionData, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok || response.status === 200) {
          console.log('Subscription sent to backend successfully');
        } else {
          console.error('Backend returned an error:', response.status);
        }
      } catch (error) {
        console.error('Failed to send subscription to backend:', error);
      }
    } else {
      console.error('Subscription keys are missing or invalid:', subscription);
    }
  };

  // Utility to convert base64 string to Uint8Array
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  useEffect(() => {
  // Are Notifications supported in the service worker?
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn('Notifications aren\'t supported.');
      return;
    }

    // Check the current Notification permission.
    // If its denied, it's a permanent block until the
    // user changes the permission
    if (Notification.permission === 'denied') {
      console.warn('The user has blocked notifications.');
      return;
    }

    // Check if push messaging is supported
    if (!('PushManager' in window)) {
      console.warn('Push messaging isn\'t supported.');
      return;
    }

    if(!authenticated){
      return;
    }

    // Check for notification permission and service worker support
    if ('Notification' in window && 'serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          // Check existing subscription
          registration.pushManager.getSubscription()
            .then((subscription) => {
              if (subscription) {
                console.log('Already subscribed:', subscription);
                subscription.unsubscribe();
              } else {
                requestNotificationPermission(registration);
              }
            })
            .catch((error) => {
              console.error('Error checking subscription:', error);
            });
        })
        .catch((error) => {
          console.error('Service Worker not ready:', error);
        });
    }
  }, []);

  // Handle expiration and logout
  useEffect(() => {
    if (expiration) {
      const expirationTime = new Date(expiration).getTime();
      const currentTime = Date.now();

      if (expirationTime - currentTime <= 0) {
        handleLogout();
        return;
      }

      const timeRemaining = expirationTime - currentTime;
      const refreshTimer = setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, timeRemaining);

      return () => clearTimeout(refreshTimer);
    }
  }, [expiration, handleLogout]);

  if (loading) {
    return <LoadingIndicator message="Loading..." />;
  }

  return (
    <div className="app">
      {!location.pathname.startsWith('/error') && !location.pathname.startsWith('/unauthorized') && authenticated && (
        <div className="app-top-box">
          <Header onLogout={handleLogout} />
        </div>
      )}
      <ScrollToTop />
      <div className="app-body">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/unauthorized"
            element={
            <PrivateRoute
            element={<UnauthorizedPage userRoles={userRoles}/>}
            authenticated={authenticated}
            roles={['BOUSER', 'FOUSER', 'BOMANAGER', 'FOMANAGER', 'ADMIN','USER']}
            userRoles={userRoles}/>}/>
          <Route
            path="/home"
            element={
            <PrivateRoute
            element={<Home />}
            authenticated={authenticated}
            roles={['BOUSER', 'FOUSER', 'BOMANAGER', 'FOMANAGER', 'ADMIN']}
            userRoles={userRoles}/>}
          />
          <Route
            path="/create-ticket"
            element={
              <PrivateRoute
              element={ <TicketContainer title="create-ticket" /> }
              roles={['FOUSER', 'BOMANAGER', 'FOMANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/update-ticket/:ticketId"
            element={
              <PrivateRoute
              element={ <TicketContainer title="update-ticket" /> }
              roles={['FOUSER', 'BOMANAGER', 'FOMANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/view-ticket/:ticketId"
            element={
              <PrivateRoute
              element={ <TicketContainer title="view-ticket" /> }
              roles={['BOUSER', 'FOUSER', 'BOMANAGER', 'FOMANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/assign-role"
            element={
              <PrivateRoute
              element={ <ManageUserPage title="view-ticket" /> }
              roles={['ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
              element={ <Dashboard  /> }
              roles={['BOMANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/login"
            element={<LoginPage authenticated={authenticated} location={location} />}
          />
          <Route path="/oauth2/redirect" element={
            <OAuth2RedirectHandler onLogin={handleLogin} />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick draggable pauseOnHover />
    </div>
  );
};

// Wrap the App component with AuthProvider
const WrappedApp = () => (
  <AuthProvider>
    <WebSocketProvider>
      <App />
    </WebSocketProvider>
  </AuthProvider>
);

export default WrappedApp;

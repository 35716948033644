import React from "react";

export default function LoadingIndicator({message}) {
  return (
    <div
      className="loading-indicator"
      role="status" // Indicates that this is a loading state
      aria-live="polite" // Lets assistive technologies know about updates
      style={{ display: "block", textAlign: "center", marginTop: "30px" }}
    >
      <span>{message}</span>
    </div>
  );
}

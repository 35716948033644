import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faEdit, faPaperclip, faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import NotificationModal from '../NotificationModal';
import '../../../styling/ChatRoom.css';
import { useApiClient } from '../../../service/API';
import { useWebSocketContext } from '../../WebSocket/WebSocketContext';
import DisplayMessage from './DisplayMessage';

const Chat = ({ ticketId, senderEmail, receiverEmail }) => {
    const apiClient = useApiClient();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [isFileUploading, setFileUploading] = useState(false);

    const messageDisplayRef = useRef(null);
    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null);

    console.log("--------------------------FIle list-------------------------",files);

    // handleNewMessage,    updateMessageReadStatus
    const { isConnected, stompClient, message, messageRead, alertMessage } = useWebSocketContext();

    // Check if the file has already been uploaded; apply versioning if necessary
    const isFileAlreadyUploaded = (file) => {
        if (!files || !Array.isArray(files)) {
            return false; // Return false if files is undefined or not an array
        }

        const existingFile = files.find(uploadedFile => uploadedFile.name === file.name);

        if (existingFile) {
            // If the file already exists, apply versioning
            let newFileName = existingFile.name;

            // Increment the version until we find a unique name
            while (files.some(uploadedFile => uploadedFile.name === newFileName)) {
                newFileName = getVersionedFileName(file.name);
            }

            return newFileName; // Return the new versioned file name
        }

        return false; // No versioning needed if the file doesn't already exist
    };

    const getVersionedFileName = (fileName) => {
        const regex = /^(.*?)(\s\(\d+\))(\.[a-zA-Z0-9]+)$/;
        const match = fileName.match(regex);

        if (match) {
            // If versioning already exists, increment the version number
            const baseName = match[1]; // The base file name without versioning
            const versionNumber = parseInt(match[2].slice(2, -1)) + 1; // Extract and increment the version number
            const extension = match[3]; // The file extension
            return `${baseName} (${versionNumber})${extension}`;
        } else {
            // If no versioning, return the original name
            return fileName;
        }
    };


    const handleNewMessage = (msg) => {
        if(msg.type==='REFRESH'){return;}
        console.log("=========handleNewMessage=========",msg);
        setMessages((prevMessages) => {
            const existingMessages = prevMessages.filter(message => message.msgId !== msg.msgId);
            return [...existingMessages, msg];
        });

        if (msg.createdBy !== senderEmail && msg.msgId) {
            apiClient.put(`/tickets/chat/update-read-status/${msg.msgId}`);
        }
    };

    const updateMessageReadStatus = (message) => {
        console.log("=========updateMessageReadStatus=========",message);
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.msgId === message.msgId ? { ...msg, messageRead: message.messageRead } : msg
            )
        );
    };

    useEffect(()=>{
        if(message){
            handleNewMessage(message);
        }
    },[message]);

    useEffect(()=>{
        if(messageRead){
            updateMessageReadStatus(messageRead);
        }
    },[messageRead])

    const fetchHistoryMessageData = useCallback(async () => {
        if (!ticketId) return;

        setMessages([]);  // Clear previous messages
        setFiles([]);      // Clear previous files

        try {
            // Fetch chat history for the given ticket ID
            const response = await apiClient.get(`/tickets/chat/${ticketId}`);

            // Log the response to verify the structure
            console.log('Chat history response:', response.data);

            // Set the fetched messages to the state
            setMessages(response.data);

            // Filter and extract file data from the response
            const files = response.data
                .filter((data) => data.filedata && data.filedata.name) // Filter out items without filedata
                .map((data) => data.filedata); // Extract filedata

            // Set the filtered files to the state
            setFiles(files);
        } catch (error) {
            // Log the full error for debugging
            console.error('Error fetching chat history:', error);

            // Extract and display an error message if available
            const errorMessage = error.response?.data?.businessExceptionDescription || 'Error fetching chat history. Please try again.';

            // Set the error message and show the notification
            setNotificationMessage(errorMessage);
            setShowNotification(true);
        }
    }, [apiClient, ticketId]);

    useEffect(() => {
        fetchHistoryMessageData();
    }, [ticketId]);

    const sendMessage = async (content, filediscussionId = null) => {
        if(content === null || content.trim() === '' && filediscussionId === null){
            return;
        } else {
            if (isConnected) {
                const message = {
                    senderEmail,
                    receiverEmail,
                    ticketId,
                    content,
                    fileId: filediscussionId,
                };
                stompClient.send(`/app/ticket/${ticketId}`, {}, JSON.stringify(message));
                setInputMessage('');
                textAreaRef.current.innerHTML = '';
                scrollToBottom();
            } else {
                setNotificationMessage('Cannot send message, WebSocket is not connected.');
                setShowNotification(true);
            }
        }
    };

    const scrollToBottom = () => {
        if (messageDisplayRef.current) {
            messageDisplayRef.current.scrollTop = messageDisplayRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(inputMessage);
        }
    };

    const uploadHandler = async (event) => {
        event.preventDefault();
        setFileUploading(true);
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length === 0) return;

        const newFiles = [];
        const uploadingFilesList = [];

        for (const file of selectedFiles) {
            const versionedFileName = isFileAlreadyUploaded(file);

            const allowedTypes = [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/pdf',
                'image/jpeg',
                'image/png',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'message/rfc822'
            ];

            const maxSize = 10 * 1024 * 1024; // 10 MB
            if (!allowedTypes.includes(file.type)) {
                setNotificationMessage('Invalid file type. Please upload valid files.');
                setShowNotification(true);
                setFileUploading(false);
                return;
            }

            if (file.size > maxSize) {
                setNotificationMessage('File size exceeds the maximum allowed size of 10 MB.');
                setShowNotification(true);
                setFileUploading(false);
                return;
            }

            if (versionedFileName) {
                // Create a new File object with the updated name and same content
                const newFile = new File([file], versionedFileName, { type: file.type });
                newFiles.push(newFile); // Add the new file to the list of files
            } else {
                newFiles.push(file); // Add the original file if no versioning is applied
            }

            console.log(newFiles);
            uploadingFilesList.push({ fileName: file.name, status: 'uploading' });
        }

        if (newFiles.length === 0) {
            setFileUploading(false);
            return;
        }

        const formData = new FormData();
        formData.append('ticketId', ticketId);
        newFiles.forEach(file => formData.append('filename', file));

        try {
            const response = await apiClient.post('/files/upload-chatfiles', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200) {
                setFiles(prevFiles => [...(prevFiles || []), response.data]);

                newFiles.forEach(file => {
                    const fileMessage = {
                        senderEmail,
                        receiverEmail,
                        ticketId,
                        content: `${file.name}`,
                        fileId: response.data.fileId,
                    };
                    sendMessage(fileMessage.content, fileMessage.fileId);
                });

                setNotificationMessage('Files uploaded successfully!');
                setShowNotification(true);
            } else {
                setNotificationMessage('Error uploading files. Please try again.');
                setShowNotification(true);
            }

            fileInputRef.current.value = null;

        } catch (error) {
            setNotificationMessage('Error uploading files. Please try again.');
            setShowNotification(true);
            fileInputRef.current.value = null;
        } finally {
            setFileUploading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };

    return (
        <div className="chat-room">
            <h2>Chat Room for Ticket ID: {ticketId}</h2>
            <div className="message-display" ref={messageDisplayRef}>
                {messages && messages.length === 0 ? (
                    <p>No messages yet.</p>
                ) : (
                    messages.map((msg) => (
                        <div
                            className={`message ${msg.createdBy === senderEmail ? 'sender' : 'receiver'}`}
                            key={msg.msgId}
                        >
                            <DisplayMessage msg={msg} formatDate={formatDate} apiClient={apiClient} />
                        </div>
                    ))
                )}
            </div>

            <div className="input-area">
                <FontAwesomeIcon icon={faPaperclip} onClick={() => fileInputRef.current.click()} />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={uploadHandler}
                    multiple
                />
                <div
                    className="form-control chat-input-area"
                    contentEditable
                    ref={textAreaRef}
                    onInput={(e) => setInputMessage(e.currentTarget.innerHTML)}
                    onPaste={(e) => e.preventDefault()}
                    onKeyPress={handleKeyPress}
                    disabled={isFileUploading}
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => sendMessage(inputMessage)}
                    disabled={!ticketId || isFileUploading}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {isFileUploading ? (
                        <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} />
                    ) : (
                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} />
                    )}
                    Send
                </button>
            </div>

            <NotificationModal
                show={showNotification}
                message={notificationMessage}
                onClose={() => setShowNotification(false)}
            />
        </div>
    );
};

export default Chat;

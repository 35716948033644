import React, { useEffect, useState } from 'react';
import '../../styling/Modal.css';

function NotificationModal({ show, message, onClose }) {
  const [manualClose, setManualClose] = useState(false);

  useEffect(() => {
    if(message === "Creating"){
      return;
    }
    if (show) {
      // Set a timer to navigate and close the modal
      const timer = setTimeout(() => {
        onClose(); // Close the modal
      }, 1500); // Show the modal for 1 seconds

      // Cleanup the timer on component unmount or when show changes
      return () => clearTimeout(timer);
    }
  }, [show, onClose, manualClose]);

  const handleClose = () => {
    setManualClose(true); // Mark that the modal is manually closed
    onClose(); // Close the modal
  };

  return (
    <div
      className={`modal fade ${show ? 'show modal-backdrop-transparent' : ''}`}
      tabIndex="-1"
      aria-labelledby="notificationModalLabel"
      aria-hidden={!show}
      role="dialog"
      style={{ display: show ? 'block' : 'none' }} // Inline style to control visibility
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="notificationModalLabel">Notification</h5>
            {message !== "Creating" &&<button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>}
          </div>
          <div className="modal-body">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationModal;

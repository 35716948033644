import React from 'react';
import ReactDOM from 'react-dom/client';
import WrappedApp from './App';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // For Bootstrap's JavaScript functionality
import './index.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <WrappedApp />
    </BrowserRouter>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Register the service worker
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);

        // Force the service worker to update if there is a new version
        registration.update();

        // Listen for the `updatefound` event to react to a new worker being installed
        registration.onupdatefound = () => {
          const newWorker = registration.installing;
          newWorker.onstatechange = () => {
            // When the new worker has been activated, take control of the page immediately
            if (newWorker.state === 'activated') {
              console.log('New service worker activated');
              if (navigator.serviceWorker.controller) {
                // New worker is activated, take control of the page immediately
                console.log('New service worker is controlling the page');
              }
            }
          };
        };
      })
      .catch(error => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

// Listen for the 'waiting' state and force the new service worker to activate immediately
navigator.serviceWorker.addEventListener('controllerchange', () => {
  console.log('Service Worker controller has changed.');
  // If there's a waiting service worker, activate it immediately
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ action: 'skipWaiting' });
  }
});


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTrash, faFileWord, faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useApiClient } from '../../service/API';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import '../../styling/FileItem.css';

const FileItem = ({ file, deleteFile, isUploading }) => {
    const { jobId } = useParams();
    const apiClient = useApiClient();
    const [fileUrl, setFileUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch file if jobId exists and file is not a Blob
    const fetchFile = async () => {
        if (!file || !file.name || !file.fileId) return;

        try {
            const response = await apiClient.get('/files/retrieve', {
                params: {
                    fileId:file.Id
                },
                responseType: 'arraybuffer',
                headers: { 'Accept': 'application/json' }
            });

            if (response.status === 200) {
                const blob = new Blob([response.data]);
                const url = URL.createObjectURL(blob);
                setFileUrl(url);
                setErrorMessage('');
            } else if (response.status === 404) {
                setErrorMessage('This file is no longer available.');
                setFileUrl('');
            } else {
                throw new Error(`Server returned status: ${response.status}`);
            }
        } catch (err) {
            // console.error('Error fetching file:', err.message);
            setErrorMessage('This file is no longer available.');
            setFileUrl('');
        }
    };

    useEffect(() => {
        let url = '';
        setErrorMessage('');

        if (file) {
            if (file instanceof Blob) {
                url = URL.createObjectURL(file);
            } else if (jobId) {
                fetchFile();
            } else {
                url = file;
            }
        }

        setFileUrl(url);

        // Clean up function to revoke object URL
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [file, jobId]);

    const handleDeleteAndNavigate = (event) => {
        event.preventDefault(); // Prevent the default action (e.g., any accidental page reload)

        if (file) {
          deleteFile(file.name); // Delete the file if it exists
        }
    };

    const renderPreview = () => {
        if (errorMessage) {
            return <div className="file-preview-text">{errorMessage}</div>;
        }

        if (!file) {
            return <FontAwesomeIcon icon={faFileAlt} />;
        }

        const fileType = file.type || '';

        if (fileType.startsWith('image/')) {
            return <img className='file-img' src={fileUrl} alt={file.name} />;
        } else if (fileType === 'application/pdf') {
            return <iframe className='file-preview' src={fileUrl} title={file.name} />;
        } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <div className="file-preview-text">Word documents are not previewable <FontAwesomeIcon icon={faFileWord} /></div>;
        } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return <div className="file-preview-text">Excel files are not previewable <FontAwesomeIcon icon={faFileExcel} /></div>;
        } else {
            return <FontAwesomeIcon icon={faFileAlt} />;
        }
    };

    return (
        <div className='file-card-container'>
            <div className='file-img-container'>
                {renderPreview()}
            </div>
            <div className='file-item'>
                <p className='file-name'>{file ? file.name : 'Unknown file'}</p>
                <div className='actions'>
                    {isUploading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={handleDeleteAndNavigate} // Call the function that deletes and navigates
                                className='delete-icon'
                            />
                            <span>✓ Uploaded</span>
                        </>
                    )
                    }
            </div>
            </div>
        </div>
    );
};

// Define prop types for validation
FileItem.propTypes = {
    file: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Blob),
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string,
            size: PropTypes.number,
            isUploading: PropTypes.bool
        })
    ]),
    deleteFile: PropTypes.func.isRequired,
};

export default FileItem;

import React from 'react';
import TicketList from '../TicketListComponents/TicketList';
import { useAuth } from '../../auth/AuthHandler';
import '../../styling/Body.css';

function Home() {
  const { userRoles } = useAuth();

  const isUserRoleOnly = userRoles.length === 1 && userRoles.includes("USER");

  return (
    <div className='body'>
      <h1>Home</h1>
      {isUserRoleOnly ? (
        <p>Contact admin to provide additional permission - role.</p>
      ) : (
        <TicketList />
      )}
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthHandler';
import { useApiClient } from '../../service/API';
import SearchableDropdown from '../TicketComponents/SearchableDropdown';

function FilterForm({ filters, pageSize, onFilterChange, onPageSizeChange, onResetFilters, onApplyFilters }) {
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const [boUserList, setBoUserList] = useState([]);
    const [foUserList, setFoUserList] = useState([]);
    const { userRoles } = useAuth();
    const fetchBoUserList = async () => {
        try {
          const userListGet = await apiClient.get('/users/bouser-list');
          const userListWithAllOption = [{
            id: 'All',
            fullname: 'All',
            email: 'All'
          }, ...userListGet.data]; // Add the object at the first index
          setBoUserList(userListWithAllOption); // Update state with new list
        } catch (error) {
          // handleApiError(error, 'Error fetching user list. Please contact IT support.');
        }
    };

    const fetchFoUserList = async () => {
        try {
          const userListGet = await apiClient.get('/users/fouser-list');
          const userListWithAllOption = [{
            id: 'All',
            fullname: 'All',
            email: 'All'
          }, ...userListGet.data]; // Add the object at the first index
          setFoUserList(userListWithAllOption); // Update state with new list
        } catch (error) {
          // handleApiError(error, 'Error fetching user list. Please contact IT support.');
        }
    };

    const handleInput = (event) => {
        const input = event.target;
        const cursorPosition = input.selectionStart; // Get the current cursor position

        // Get the current value of the input
        let value = input.value;

        // Remove any non-numeric characters and any dots (.)
        value = value.replace(/[^0-9]/g, '');  // Allow only digits, remove anything else

        // Update the input value with the cleaned numeric value
        input.value = value;

        // Restore the cursor position (keeping it the same after the change)
        input.setSelectionRange(cursorPosition, cursorPosition);

        // Call the onChange handler with the updated value
        onFilterChange(event);
    };

    useEffect(()=>{
        if(!['BOUSER'].some(role => userRoles.includes(role))){
            fetchBoUserList();
        }
        if(!['FOUSER'].some(role => userRoles.includes(role))){
            fetchFoUserList();
        }
    },[]);

    return (
        <form className="mb-3">
        <div className="row g-3">
            <div className="col-md-6 col-lg-3">
            <label htmlFor="ticketId" className="form-label">Ticket Id</label>
            <input
                type="text"
                id="ticketId"
                name="ticketId"
                placeholder="Ticket Id"
                value={filters.ticketId}
                onInput={handleInput}  // Handle the input event to allow only digits and a dot
                className="form-control"
                step="1"
                min="0"
                pattern="[0-9]+"
                inputMode="numeric"
            />
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="sysFreightRef" className="form-label">Sysfreight Refrence No.</label>
            <input
                type="text"
                id="sysFreightRef"
                name="sysFreightRef"
                placeholder="Sysfreight Reference No."
                value={filters.sysFreightRef}
                onChange={onFilterChange}
                className="form-control"
                maxLength={255}  // Set the maximum length to 255
            />
            </div>
            {['BOUSER', 'FOMANAGER', 'BOMANAGER','ADMIN'].some(role => userRoles.includes(role))&&<div className="col-md-6 col-lg-3">
            <label htmlFor="createdBy" className="form-label">Created By</label>
            <SearchableDropdown
                id="createdBy"
                name="createdBy"
                options={foUserList}
                selectedValue={filters.createdBy === ''?'All':filters.createdBy}
                onSelectedChange={(value)=>onFilterChange({name:"createdBy",value: value==='All'?'':value})}
                placeholder="Select user"
            />
            </div>}
            {['FOUSER', 'FOMANAGER', 'BOMANAGER','ADMIN'].some(role => userRoles.includes(role))&&<div className="col-md-6 col-lg-3">
            <label htmlFor="assignTo" className="form-label">Assign To</label>
            <SearchableDropdown
                id="assignTo"
                name="assignTo"
                options={boUserList}
                selectedValue={filters.assignTo === ''?'All':filters.assignTo}
                onSelectedChange={(value)=>onFilterChange({name:"assignTo",value: value==='All'?'':value})}
                placeholder="Select user"
            />
            </div>}
            <div className="col-md-6 col-lg-3">
            <label htmlFor="status" className="form-label">Status</label>
                <select
                    id="status"
                    className="form-select"
                    name="status"
                    placeholder="Status"
                    value={filters.status}
                    onChange={onFilterChange}
                    >
                    <option value="">All</option>
                    <option value="NEW">NEW</option>
                    {['FOMANAGER', 'BOMANAGER','ADMIN'].some(role => userRoles.includes(role))&&<option value="PENDING">PENDING</option>}
                    <option value="URGENT">URGENT</option>
                    <option value="ERROR">ERROR</option>
                    <option value="REWORK">REWORK</option>
                    <option value="COMPLETED">COMPLETED</option>
                    <option value="DONE">DONE</option>
                    <option value="CANCELLED">CANCELLED</option>
                </select>
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="stage" className="form-label">Stage</label>
                <select
                    id="stage"
                    className="form-select"
                    name="stage"
                    placeholder="stage"
                    value={filters.stage}
                    onChange={onFilterChange}
                    >
                    <option value="">All</option>
                    {
                        ['Booking Creation', 'HBL Creation', 'MBL Creation', 'Payment Request', 'Shipped On Board', 'Billing', 'Vendor Invoice']
                            .map((listValue) => {
                            return <option key={listValue} value={listValue}>{listValue}</option>;
                            })
                    }
                </select>
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="dropdownMenuButton" className="form-label">Page Size</label>
            <div className="dropdown custom-dropdown">
                <button className="btn btn-outline-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                Page Size: {pageSize}
                <i className="bi bi-chevron-down ms-2"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(10)}>10</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(25)}>25</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(50)}>50</a></li>
                <li><a className="dropdown-item" href="#!" onClick={() => onPageSizeChange(100)}>100</a></li>
                </ul>
            </div>
            </div>
                <div className="row gap-2 me-md-2 mt-2 ms-auto">
                    <button
                        type="button"
                        className="btn btn-primary col-md-2"
                        onClick={onApplyFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary col-md-2"
                        onClick={onResetFilters}
                    >
                        Reset Filters
                    </button>
                    {['FOUSER', 'FOMANAGER', 'BOMANAGER','ADMIN'].some(role => userRoles.includes(role))&&<button
                    type="button"
                    className="btn btn-primary col-md-2 ms-auto"
                    onClick={() => navigate('/create-ticket')}
                    >
                    Create Ticket
                    </button>}
                </div>
            </div>
        </form>
    );
}

export default FilterForm;

import React, { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '../../styling/PDFViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.mjs';

function PDFViewer({fileUrl}){
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);

  // Handle successful loading of PDF document
  const onLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  // Handle errors during PDF loading
  const onLoadError = useCallback((error) => {
    // console.error('Error loading PDF:', error);
    setError('Error loading PDF.');
  }, []);

  // Render error message if there's an error
  if (error) {
    return <div>{error}</div>;
  }

  // Render the PDF document and its pages
  return (
    <div className="pdf-container">
      <Document
        file={fileUrl}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
        loading="Loading PDF..."
      >
        {[...Array(numPages).keys()].map((pageNumber) => (
          <div key={pageNumber}> {/* Add key here for outer div */}
            <Page
              key={pageNumber + 1} // This is fine for Page component
              pageNumber={pageNumber + 1}
              scale={1.0}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              renderMode="canvas"
            />
            <div className='pageNumberCount'>
              <p>Page {pageNumber + 1} of {numPages}</p>
            </div>
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;

import { useState, useEffect } from 'react';
import { useApiClient } from '../service/API'; // Adjust path as needed

const useTickets = (filters, pageSize, currentPage) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [executeFilter, setExecuteFilter] = useState(true);
  const [error, setError] = useState(null); // State to manage error messages
  const [notification, setNotification] = useState({
    show: false,
    message: '',
  });

  const apiClient = useApiClient();

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      setError(null); // Clear previous errors
      
      try {

        const sanitizedFilters = Object.keys(filters).reduce((acc, key) => {
          acc[key] = filters[key] === 'All' ? '' : filters[key];
          return acc;
        }, {});

        const response = await apiClient.get('/tickets', {
          params: { ...sanitizedFilters, pageSize, page: currentPage },
        });

        setTickets(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        // console.error('Error fetching tickets:', error.response ? {
        //   status: error.response.status,
        //   statusText: error.response.statusText,
        //   data: error.response.data,
        //   config: error.config,
        // } : error.message);
        
        // Provide user-friendly error messages
        const errorMessage = error.response?.data?.businessExceptionDescription || 'Failed to fetch tickets. Please try again later.';
        setError(errorMessage);
        setNotification({
          show: true,
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    if (executeFilter) {
      fetchTickets();
      setExecuteFilter(false);
    }
  }, [executeFilter, pageSize, currentPage, filters, apiClient]); // Ensure dependencies include filters and apiClient

  const handleCloseNotification = () => {
    setNotification({ ...notification, show: false });
  };

  return {
    tickets,
    loading,
    totalPages,
    setExecuteFilter,
    error,
    notification,
    handleCloseNotification, // Provide method to close notification
  };
};

export default useTickets;

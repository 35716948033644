import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants";
import { decodeJwt } from "../util/APIUtils"; // Ensure you import decodeJwt

const OAuth2RedirectHandler = ({ onLogin }) => {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const token = getUrlParameter('token');
  const error = getUrlParameter('error');

  useEffect(() => {
    if (token) {
      // Save token in localStorage
      localStorage.setItem(ACCESS_TOKEN, token);
      
      // Decode the token and extract user details
      const { authorities, expiration, userEmail, userName } = decodeJwt();

      // Call the onLogin callback to set user state
      onLogin(authorities, expiration, userEmail, userName);
      
      // Set redirect state to navigate to home
      setRedirect("/home");
    } else if (error) {
      // console.error('Authentication error:', error);
      // Set redirect state to navigate to login with error
      setRedirect("/login");
    }
  }, [token, error, onLogin]);

  // Redirect based on state
  if (redirect) {
    return <Navigate to={redirect} state={{ error }} />;
  }

  return null; // Render nothing while processing
};

export default OAuth2RedirectHandler;

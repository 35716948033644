import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TicketForm from './TicketForm';
import Chat from './Discussion/Chat';
import '../../styling/TicketContainer.css'; // Import the CSS file
import { useApiClient } from '../../service/API';
import { useAuth } from '../../auth/AuthHandler'; // Adjust path as needed
import { useWebSocketContext } from '../WebSocket/WebSocketContext';

function TicketContainer({ title }) {
  const { ticketId } = useParams(); // Get ticketId from URL
  const apiClient = useApiClient();
  const { userEmail } = useAuth();
  const [receiverEmail, setReceiverEmail] = useState(null);
  const [existingTicket, setExistingTicket] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true); // Track loading state
  const [showChat, setShowChat] = useState(true); // Track showChat with state

  // onMessageReceived
  const { message } = useWebSocketContext();

  const fetchTicketData = async () => {
    if ((title === 'view-ticket' || title === 'update-ticket') && ticketId) {
      try {
        const response = await apiClient.get(`/tickets/${ticketId}`);

        if (response && response.data) {
          setExistingTicket(response.data);
          setShowChat(response.data.status !== 'PENDING'); // Show chat unless status is 'PENDING'

          // Set the receiver email based on ticket data
          if (response.data.assignedTo === userEmail) {
            setReceiverEmail(response.data.createdBy);
          } else {
            setReceiverEmail(response.data.assignedTo);
          }
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false); // Stop loading after data fetch or error
      }
    } else {
      setLoading(false);
    }
  };

  const onMessageReceived = (msg) => {
    if(msg.type === 'REFRESH'){
      fetchTicketData();
    }
  };

  useEffect(()=>{
    if(message){
      onMessageReceived(message);
    }
  },[message]);

  useEffect(() => {
    if (ticketId) {
      fetchTicketData();
    } else {
      setLoading(false);
    }
  }, [ticketId, title]); // Dependencies: ticketId and title

  // If data is still loading, show a loading message
  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(showChat);

  return (
    <div className='ticket-container'>
      <div className={`ticket-form-container ${title !== 'view-ticket' || !showChat ? 'dont-show-chat' : 'show-chat'}`}>
        <TicketForm title={title} ticketInfo={existingTicket} ticketId={ticketId} />
      </div>

      {/* Only show chat if showChat is true, existingTicket is present, and title is 'view-ticket' */}
      {(showChat && existingTicket && title === 'view-ticket') && (
        <div className='ticket-discussion-container'>
          <Chat senderEmail={userEmail} ticketId={ticketId} receiverEmail={receiverEmail} />
        </div>
      )}
    </div>
  );
}

export default TicketContainer;

import React, { useReducer, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../../service/API';
import useTickets from '../../hooks/useTickets';
import FilterForm from './FilterForm';
import TicketTable from './TicketTable';
import PaginationControls from './PaginationControls';
import NotificationModal from '../TicketComponents/NotificationModal';
import { useAuth } from '../../auth/AuthHandler';
import { useWebSocketContext } from '../WebSocket/WebSocketContext';
import '../../styling/TicketList.css';

// Reducer for managing state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action.payload, currentPage: 0 };
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 0 };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: { ticketId: '', createdBy: '', assignTo: '', status: '', sysFreightRef: '', stage: '', sortBy: 'ticketId', sortDirection: 'ASC' },
        pageSize: 10,
        currentPage: 0
      };
    default:
      return state;
  }
};

const TicketList = () => {
  const apiClient = useApiClient();
  const { userEmail } = useAuth();
  const [state, dispatch] = useReducer(reducer, {
    filters: { ticketId: '', createdBy: '', assignTo: '', status: '', sysFreightRef: '', stage: '', sortBy: 'ticketId', sortDirection: 'ASC' },
    pageSize: 10,
    currentPage: 0
  });

  // onMessageReceivedForAlert
  const { isConnected,
    stompClient,
    message,
    messageRead,
    alertMessage,
    mainRefresh,
    setMainRefresh } = useWebSocketContext();

  const {
    tickets,
    loading,
    totalPages,
    setExecuteFilter,
    error,
  } = useTickets(state.filters, state.pageSize, state.currentPage, setMainRefresh);

  const navigate = useNavigate();
  const [notificationMessage, setNotificationMessage] = useState(''); // Notification message

  useEffect(()=>{
    setExecuteFilter(true);
  },[mainRefresh]);

  // Handler functions
  const handleFilterChange = (event) => {
    let fieldName, fieldValue; // Declare variables outside the if block

    if (event && event.target && event.target.name) {
      event.preventDefault();
      const { name, value } = event.target;
      fieldName = name;
      fieldValue = value;
    } else {
      const { name, value } = event;
      fieldName = name;
      fieldValue = value;
    }

    // Now you can use fieldName and fieldValue safely outside the if block
    dispatch({ type: 'SET_FILTERS', payload: { ...state.filters, [fieldName]: fieldValue } });
  };

  const onReverseSort = (name) => {
    const newSortDirection = state.filters.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    dispatch({ type: 'SET_FILTERS', payload: { ...state.filters, sortBy:name, sortDirection: newSortDirection } });
    setExecuteFilter(true);
  };

  const handlePageSizeChange = (size) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: size });
  };

  const handleResetFilters = (e) => {
    e.preventDefault();
    dispatch({ type: 'RESET_FILTERS' });
    setExecuteFilter(true);
  };

  const applyFilter = (e) =>{
    e.preventDefault();
    setExecuteFilter(true);
  }

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      dispatch({ type: 'SET_CURRENT_PAGE', payload: page });
      setExecuteFilter(true);
    }
  };

  const handleViewButton = (ticketId) => {
    navigate(`/view-ticket/${ticketId}`);
  };

  const handleCERButton = async (status, ticketId) =>{
    try {
      // Send the patch request for approval
      const response = await apiClient.patch(`/tickets/${status}/${ticketId}`);
      // After successful approval, trigger the ticket list refresh
      if(['REWORK','ERROR'].includes(status) ){
        const dataId = response.data.id;
        navigate(`/update-ticket/${dataId}`);
      }else{
        setExecuteFilter(true);  // This will refetch the tickets based on the current filters and page
        setNotificationMessage(`Ticket ${ticketId} updated successful to ${status}!`);
      }
    } catch (error) {
      // Handle error if needed (optional: display an error message)
      // console.error('Error during approval:', error.response ? error.response.data : error.message);
    }
  };

  const handleApprovalButton = async (ticketId, status) => {
    try {
      // Send the patch request for approval
      await apiClient.patch(`/tickets/urgent-approval/${ticketId}/${status}`);

      // After successful approval, trigger the ticket list refresh
      setExecuteFilter(true);  // This will refetch the tickets based on the current filters and page
    } catch (error) {
      // Handle error if needed (optional: display an error message)
      // console.error('Error during approval:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (error) {
      setNotificationMessage(error);
    }
  }, [error]);

  return (
    <div className='ticket-list'>
      <FilterForm
        filters={state.filters}
        pageSize={state.pageSize}
        onFilterChange={handleFilterChange}
        onPageSizeChange={handlePageSizeChange}
        onResetFilters={handleResetFilters}
        onApplyFilters={applyFilter}
      />
      <TicketTable
        loading={loading}
        tickets={tickets}
        filters={state.filters}
        onViewButton={handleViewButton}
        onApprovalButton={handleApprovalButton}
        onCERButton={handleCERButton}
        onReverseSort={onReverseSort}
      />
      <PaginationControls
        currentPage={state.currentPage}
        totalPages={totalPages}
        onPreviousPage={() => handlePageChange(state.currentPage - 1)}
        onNextPage={() => handlePageChange(state.currentPage + 1)}
      />
      {/* Notification Modal */}
      <NotificationModal
        show={Boolean(notificationMessage)}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
      />
    </div>
  );
};

export default TicketList;

import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faDownload, faXmark } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

const DisplayMessage = ({ msg, formatDate, apiClient }) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isFileDeleted, setIsFileDeleted] = useState(false);

    // Ref to keep track of whether the file has already been fetched
    const fileFetchedRef = useRef(false);

    // Function to fetch the file from the server
    const fetchFile = async () => {
        if(!msg.filedata){return;}
        setFileName(msg.filedata.name);
        if (fileFetchedRef.current) {
            // If the file is already fetched, no need to fetch again
            return;
        }

        try {
            setIsRetrieving(true);
            const response = await apiClient.get('/files/retrieve-msgfile', {
                params: { fileId: msg.filedata.fileId },
                responseType: 'arraybuffer',  // Expecting binary data
                headers: { 'Accept': 'application/json' },
            });

            // If you want to process the binary data (e.g., convert it to text or a string)
            const textDecoder = new TextDecoder('utf-8');  // Assuming the data is UTF-8 encoded
            const message = textDecoder.decode(new Uint8Array(response.data));

            if (response.status === 200) {
                if(message === 'File deleted'){
                    setIsFileDeleted(true);
                }else{
                    const blob = new Blob([response.data]);
                    const url = URL.createObjectURL(blob);
                    setFileUrl(url);
                    setFileName(msg.filedata.name);  // Update fileName (from server response or message)
                    fileFetchedRef.current = true; // Mark the file as fetched
                }
            } else {
                throw new Error(`Server returned status: ${response.status}`);
            }
        } catch (err) {
            // console.error('Error fetching file:', err.message);
        } finally {
            setIsRetrieving(false);
        }
    };

    // Function to trigger the download
    const handleDownload = () => {

        if (fileUrl && fileName) {
            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileName;  // Set the download attribute with file name
            a.click();  // Programmatically trigger a click to start the download
            // Optionally revoke the URL after download
            URL.revokeObjectURL(fileUrl);
        }
    };

    // Fetch the file when the message is updated
    useEffect(() => {
        if (msg && msg.filedata !== null && !fileFetchedRef.current) {
            fetchFile();
        }

        return () => {
            // Clean up the URL object when component unmounts or file changes
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [msg, fileUrl]); // Ensure the effect runs when msg changes

    return (
        <div>
            {msg.filedata !== null ? (
                <div className="msgfile-message">
                    <div className="msgfile-link">
                        {/* The message content for the file */}
                        <a target="_blank" rel="noopener noreferrer">
                            {msg.content}
                        </a>
                        {isRetrieving ? (
                            <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} />
                        ) : (
                            isFileDeleted ?
                            <FontAwesomeIcon
                            icon={faXmark}
                            style={{ padding: '5px', borderRadius: '5px', marginRight: '5px', backgroundColor: 'red' }}
                            />
                            :
                            <div className="file-download">
                                {/* The download button */}
                                <button onClick={handleDownload} className="download-btn">
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <strong>{msg.createdBy}: {formatDate(msg.createdDate)}</strong>
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.content) }} />
                </>
            )}
            <span className={`read-status ${msg.messageRead ? '' : 'unread'}`}>
                {msg.messageRead ? '✓ Read' : '✗ Unread'}
            </span>
        </div>
    );
};

export default DisplayMessage;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from "../../util/APIUtils.js";
import { ACCESS_TOKEN } from "../../constants";

const ErrorPage = () => {
    const query = new URLSearchParams(useLocation().search);
    const message = query.get('message') || "An unexpected error occurred.";
    const navigate = useNavigate();
    const token = localStorage.getItem(ACCESS_TOKEN);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(token){
                logoutUser();
            }
            navigate('/login'); // Redirect to /home after 0.5 seconds
        }, 700);

        // Directly navigate if the error message indicates a cancel
        if (message.includes("Login was canceled")) {
            clearTimeout(timer);
            navigate('/login');
        }

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    return (
        <div>
            <h1>Error</h1>
            <p>{message}</p>
            <p>You will be redirected shortly...</p>
        </div>
    );
};

export default ErrorPage;

import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { API_BASE_URL, ACCESS_TOKEN, base } from '../../constants';
import { useAuth } from '../../auth/AuthHandler';

// Create a context to provide stompClient and subscription management
const WebSocketContext = createContext();

export const useWebSocketContext = () => {
    return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [stompClient, setStompClient] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageRead, setMessageRead] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [pendingAlert, setPendingAlert] = useState([]);
    const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
    const { authenticated, userEmail, handleLogout } = useAuth();
    const [ticketId, setTicketId] = useState(null);
    const location = useLocation();

    const socketRef = useRef(null);
    const clientRef = useRef(null);
    const subscriptionsRef = useRef({});
    const connectingRef = useRef(false); // Track if we're currently connecting

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        if (pathSegments.length > 2 && pathSegments[1] === 'view-ticket') {
            setTicketId(pathSegments[2]); // Get the ticketId from the route params
        } else {
            setTicketId(null); // Reset the ticketId when not on a ticket-related page
        }
    }, [location]);

    const updateConnectionStatus = (status) => {
        if (isConnected !== status) {
            setIsConnected(status);
        }
    };

    // Function to connect WebSocket
    const connectWebSocket = () => {
        // Prevent multiple connections if already connecting
        if (connectingRef.current) return;

        const token = localStorage.getItem(ACCESS_TOKEN);
        if (!API_BASE_URL || !token) {
            console.error("API_BASE_URL or access token is not defined.");
            return;
        }

        connectingRef.current = true; // Set connecting flag to true

        console.log(`Connecting WebSocket to ${API_BASE_URL}/chat?token=${token}`);

        socketRef.current = new SockJS(`${API_BASE_URL}/chat?token=${token}`);
        const client = Stomp.over(() => socketRef.current);

        client.connect({}, (frame) => {
            updateConnectionStatus(true);
            connectingRef.current = false; // Reset connecting flag
            setReconnectionAttempts(0);

            cleanupSubscriptions();

            // Subscribe to alert messages for user
            if (userEmail && !subscriptionsRef.current.alert) {
                subscriptionsRef.current.alert = client.subscribe(`/topic/alert/${userEmail}`, (message) => {
                    const msg = JSON.parse(message.body);
                    setAlertMessage(msg);
                    if (msg.type === 'PENDING') {
                        setPendingAlert((prevAlerts) => [...prevAlerts, msg]);
                    }
                });
            }

            // Subscribe to ticket-specific messages
            if (ticketId && !subscriptionsRef.current.ticket) {
                subscriptionsRef.current.ticket = client.subscribe(`/topic/ticket/${ticketId}`, (message) => {
                    const msg = JSON.parse(message.body);
                    setMessage(msg);
                });
            }

            // Subscribe to message read notifications for a specific ticket
            if (ticketId && !subscriptionsRef.current.msgRead) {
                subscriptionsRef.current.msgRead = client.subscribe(`/topic/msg-read/${ticketId}`, (message) => {
                    const msg = JSON.parse(message.body);
                    setMessageRead(msg);
                });
            }

            // Handle WebSocket close event
            client.onclose = () => {
                updateConnectionStatus(false);
                console.log('WebSocket connection closed. Attempting to reconnect...');
                setReconnectionAttempts((prev) => prev + 1);
            };
        }, (error) => {
            console.error('Connection error: ', error);
            updateConnectionStatus(false);
            connectingRef.current = false; // Reset connecting flag
            setReconnectionAttempts((prev) => prev + 1);
        });

        clientRef.current = client;
        setStompClient(client);
    };

    // Cleanup subscriptions
    const cleanupSubscriptions = () => {
        Object.keys(subscriptionsRef.current).forEach((key) => {
            if (subscriptionsRef.current[key]) {
                subscriptionsRef.current[key].unsubscribe();
                delete subscriptionsRef.current[key];
            }
        });
    };

    // Effect to handle WebSocket connection and reconnection with exponential backoff
    useEffect(() => {
        if (!authenticated) return;

        // Check if clientRef or isConnected is not established, then connect
        if (!clientRef.current || !isConnected) {
            connectWebSocket();
        }

        if (!isConnected) {
            const delay = Math.min(1000 * Math.pow(2, reconnectionAttempts), 30000); // Exponential backoff
            const timer = setTimeout(() => {
                console.log(`Reconnecting in ${delay / 1000} seconds...`);
                connectWebSocket();
            }, delay);

            return () => {
                clearTimeout(timer);
            };
        }

        return () => {
            if (clientRef.current) {
                clientRef.current.disconnect();
                updateConnectionStatus(false);
                console.log('WebSocket disconnected on component unmount');
            }
        };
    }, [isConnected, reconnectionAttempts, ticketId, userEmail]);

    // Effect to detect online/offline and tab visibility changes
    useEffect(() => {
        const handleOnline = () => {
            console.log('Browser back online');
            if (clientRef.current && !clientRef.current.connected) {
                updateConnectionStatus(false);  // Reset connection if not connected
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                console.log('Browser tab is active');
                if (clientRef.current && !clientRef.current.connected) {
                    updateConnectionStatus(false);  // Reset connection if not connected
                }
            }
        };

        const handleFocus = () => {
            console.log('Browser tab is focused again');
            if (clientRef.current && !clientRef.current.connected) {
                updateConnectionStatus(false);  // Reset connection if not connected
            }
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('focus', handleFocus);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('online', handleOnline);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isConnected]);

    const handleClosePendingNotification = (msgId) => {
        setPendingAlert((prevNotifications) =>
            prevNotifications.filter((notification) => notification.msgId !== msgId)  // Remove the notification from the list
        );
    };

    if(clientRef.current && !clientRef.current.connected){
        if(authenticated){
            updateConnectionStatus(false);
            return <div>Connecting...</div>
        }
    }

    return (
        <WebSocketContext.Provider value={{
            isConnected,
            stompClient,
            message,
            messageRead,
            alertMessage
        }}>
            <div>
                {children}
                {pendingAlert.length > 0 && (
                    <div className="pending-notifications-list">
                        <ul>
                            {pendingAlert.map((notification) => (
                                <li key={notification.msgId}>
                                    <div className="pending-notification-item">
                                        <p>{notification.message}</p>
                                        <button
                                            onClick={() => handleClosePendingNotification(notification.msgId)}
                                            className="btn-close">X
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </WebSocketContext.Provider>
    );
};

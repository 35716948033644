import React, { useEffect, useState } from 'react';
import {useApiClient} from '../../service/API'; // Ensure this is properly configured
import PDFViewer from './PDFViewer';
import DocxViewer from './DocxViewer';
import ExcelViewer from './ExcelViewer';
import EmlViewer from './EmlViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../styling/FileViewer.css'; // Import the CSS file

const FileViewer = ({ folderName, fileInfo }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [name, setFileName] = useState(fileInfo.name);
  const [error, setError] = useState(null);
  const [fileExtension, setFileExtension] = useState('');
  const apiClient = useApiClient();
  console.log(fileInfo);

  const fetchFile = async () => {
    try {
      const response = await apiClient.get('/files/retrieve', {
        params: {
          fileId: fileInfo.fileId
        },
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json' }
      });

      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        setFileUrl(url);

        // Set default filename based on fileId or a generic name
        setFileName(fileInfo.name);

        const extension = fileInfo.name.split('.').pop().toLowerCase();
        setFileExtension(extension);
      } else {
        throw new Error(`Server returned status: ${response.status}`);
      }
    } catch (err) {
      // console.error('Error fetching file:', err.message);
      setError('Unable to fetch the file.');
    }
  };

  useEffect(() => {
    if (fileInfo) {
      fetchFile();
    }
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileInfo, folderName]);

  if (error) {
    return <div className="file-preview-text error">{error}</div>;
  }

  if (!fileUrl) {
    return (
      <div className="file-preview-text">
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        Loading file...
      </div>
    );
  }

  return (
    <div className="file-preview-container">
      <div className='file-header'>
        <div className="file-name">{name || 'Untitled'}</div>
        <div className="file-download">
          <a href={fileUrl} download={name} className="download-button">
            Download File <FontAwesomeIcon icon={faDownload} />
          </a>
        </div>
      </div>
      <div className="file-preview">
        {['jpg', 'png', 'jpeg'].includes(fileExtension) ? (
          <img className='file-img' src={fileUrl} alt={name} />
        ) : fileExtension === 'pdf' ? (
          <PDFViewer fileUrl={fileUrl} />
        ) : fileExtension === 'docx' ? (
          <DocxViewer fileUrl={fileUrl} />
        ) : ['xls', 'xlsx'].includes(fileExtension) ? (
          <ExcelViewer fileUrl={fileUrl} />
        ) : fileExtension === 'eml' ? (
          <EmlViewer fileUrl={fileUrl} />
        ) : (
          <div className="file-preview-text">Preview not available for this file type</div>
        )}
      </div>
    </div>
  );
};

export default FileViewer;
